import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import Layout from '../../components/layout'
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'
import Seo from '../../components/seo'
import HeroImageA from '../../components/MainHero'

const GalleryKitchen = () => {
  const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, max-content));
    justify-content: space-evenly;

    column-gap: 1em;
    row-gap: 2em;
  `
  // for staticImage start
  const width = 360
  const height = 360
  // for staticImage end
  return (
    <Layout>
      <Seo
        title="Commercial Kitchen Welding Gallery"
        description="Some of our work"
      />

      {/* Hero Image is found in /components/MainHero.js */}
      <HeroImageA />

      <SiteWidthWrapper>
        <h1>Commercial Kitchen Gallery</h1>
        <p style={{ textAlign: `center`, marginBottom: `64px` }}>
          Below is a brief collection of our work. This gallery includes a few
          in-progress pieces, befores, and afters in no particular order.
        </p>

        <div style={{ width: `100%`, margin: `0 auto` }}>
          <GridWrapper>
            <StaticImage
              src="../../images/Commercial Kitchens/Counter-Top-2.jpg"
              alt="gallery image 1"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="eager"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Counter-Top-3.jpg"
              alt="gallery image 1"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="eager"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Counter-Top-4.jpg"
              alt="gallery image 2"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="eager"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Counter-Top-5.jpg"
              alt="gallery image 4"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="eager"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Counter-Top-and-Sink.jpg"
              alt="gallery image 4"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Counter-Top-and-Sink-2.jpg"
              alt="gallery image 5"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Counter-Top.jpg"
              alt="gallery image 6"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Hood-Fan-and-Prep-Space.jpg"
              alt="gallery image 7"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Hood-Fan-and-Prep-Space-2.jpg"
              alt="gallery image 8"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Hood-Fan-and-Prep-Space-3.jpg"
              alt="gallery image 9"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Hood-Fan-and-Prep-Space-4.jpg"
              alt="gallery image 10"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Pre-Fabrication.jpg"
              alt="gallery image 11"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Pre-Fabrication-2.jpg"
              alt="gallery image 12"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Pre-Fabrication-3.jpg"
              alt="gallery image 13"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Shelves.jpg"
              alt="gallery image 14"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Storage.jpg"
              alt="gallery image 15"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Wall-Coverings-in-Prep-Space.jpg"
              alt="gallery image 16"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Commercial Kitchens/Wall-Coverings-in-Prep-Space-2.jpg"
              alt="gallery image 17"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
          </GridWrapper>
        </div>
      </SiteWidthWrapper>
    </Layout>
  )
}

export default GalleryKitchen
