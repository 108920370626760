import * as React from "react"
import styled from "@emotion/styled"
import HomeHero from "../images/bg/KustomKreationsHome.jpg"

const MainHero = styled.div`
width: 100%;
height: 450px;
overflow: hidden;
background: url(${HomeHero}) center bottom;
background-repeat: no-repeat;
background-size: cover;
margin-bottom: 64px;
`

const HeroImageA = () => (
    <>
        <MainHero />
    </>
)

export default HeroImageA